import React, { useEffect, useState, useRef, Suspense  } from "react";
import api from "../../services/api";
import '../../App.css';
import './Carton.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrash,faStar} from "@fortawesome/free-solid-svg-icons";

function CartonEspecial(props) {
    const {
        Bolas,
        CartonesSaved,
        SaveCarton,
        Cartones,
        Sorteo,
        Order,
        FooterName,
        HideName,
        noDelete,
        marcaAgua,
        listMode,
        Ribbon,
        handlerSelect
    } = props


    const [visible, setVisible] = useState("comp-carton");    
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const listRef = useRef(null);
    const batchSize = 20; // Tamaño del lote
    // Observador de intersección para detectar cuando el final de la lista es visible
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
            cargarMasItems();
        }
        }, { threshold: 1 });

        if (listRef.current) {
        observer.observe(listRef.current);
        }

        // Limpiar observador cuando el componente se desmonta
        return () => {
        if (listRef.current) {
            observer.unobserve(listRef.current);
        }
        };
    }, [items]); // Observar cambios en los items para volver a adjuntar el observador

     // Función para cargar más elementos en la lista
    const cargarMasItems = () => {
        setLoading(true);
        // Simulación de carga de datos (aquí deberías cargar los datos reales)
        setTimeout(() => {
        const newItems = [];
        for (let i = 0; i < batchSize; i++) {
            newItems.push({ id: items.length + i + 1 });
        }
        setItems(prevItems => [...prevItems, ...newItems]);
        setLoading(false);
        }, 1000); // Simulación de retardo de carga
    };

    const Marcar2 = (props) =>{
        let bolasStr = props.numeros;
        bolasStr = bolasStr.replaceAll('\[','');
        bolasStr = bolasStr.replaceAll('\]','');
        let bolasArr = bolasStr.split(",");
        let template = <></>;
        let status = [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];        
        let Ganador = true;
        for(let x = 0 ; x < status.length;x++){
            if(status[x] == false){
                x = 100;
                Ganador = false;
            }
        }
        for (let index = 0;index < Bolas.length;index++){            
            if(bolasArr[0].toString()==Bolas[index].toString()){status[0]=true}
            if(bolasArr[1].toString()==Bolas[index].toString()){status[1]=true}
            if(bolasArr[2].toString()==Bolas[index].toString()){status[2]=true}
            if(bolasArr[3].toString()==Bolas[index].toString()){status[3]=true}
            if(bolasArr[4].toString()==Bolas[index].toString()){status[4]=true}
            if(bolasArr[5].toString()==Bolas[index].toString()){status[5]=true}
            if(bolasArr[6].toString()==Bolas[index].toString()){status[6]=true}
            if(bolasArr[7].toString()==Bolas[index].toString()){status[7]=true}
            if(bolasArr[8].toString()==Bolas[index].toString()){status[8]=true}
            if(bolasArr[9].toString()==Bolas[index].toString()){status[9]=true}
            if(bolasArr[10].toString()==Bolas[index].toString()){status[10]=true}
            if(bolasArr[11].toString()==Bolas[index].toString()){status[11]=true}
            status[12]=true;
            if(bolasArr[13].toString()==Bolas[index].toString()){status[13]=true}
            if(bolasArr[14].toString()==Bolas[index].toString()){status[14]=true}
            if(bolasArr[15].toString()==Bolas[index].toString()){status[15]=true}
            if(bolasArr[16].toString()==Bolas[index].toString()){status[16]=true}
            if(bolasArr[17].toString()==Bolas[index].toString()){status[17]=true}
            if(bolasArr[18].toString()==Bolas[index].toString()){status[18]=true}
            if(bolasArr[19].toString()==Bolas[index].toString()){status[19]=true}
            if(bolasArr[20].toString()==Bolas[index].toString()){status[20]=true}
            if(bolasArr[21].toString()==Bolas[index].toString()){status[21]=true}
            if(bolasArr[22].toString()==Bolas[index].toString()){status[22]=true}
            if(bolasArr[23].toString()==Bolas[index].toString()){status[23]=true}
            if(bolasArr[24].toString()==Bolas[index].toString()){status[24]=true}
            template = (<>
                <div className={Ganador?"carton-row carton-row-1 winner":"carton-row carton-row-1"}>
                    <div className="carton-column carton-column-b" data-salio={status[1-1]}>{bolasArr[1-1]}</div>
                    <div className="carton-column carton-column-i" data-salio={status[6-1]}>{bolasArr[6-1]}</div>
                    <div className="carton-column carton-column-n" data-salio={status[11-1]}>{bolasArr[11-1]}</div>
                    <div className="carton-column carton-column-g" data-salio={status[16-1]}>{bolasArr[16-1]}</div>
                    <div className="carton-column carton-column-o" data-salio={status[21-1]}>{bolasArr[21-1]}</div>
                </div>
                <div className={Ganador?"carton-row carton-row-2  winner":"carton-row carton-row-2"}>
                    <div className="carton-column carton-column-b" data-salio={status[2-1]}>{bolasArr[2-1]}</div>
                    <div className="carton-column carton-column-i" data-salio={status[7-1]}>{bolasArr[7-1]}</div>
                    <div className="carton-column carton-column-n" data-salio={status[12-1]}>{bolasArr[12-1]}</div>
                    <div className="carton-column carton-column-g" data-salio={status[17-1]}>{bolasArr[17-1]}</div>
                    <div className="carton-column carton-column-o" data-salio={status[22-1]}>{bolasArr[22-1]}</div>
                </div>
                <div className={Ganador?"carton-row carton-row-3  winner":"carton-row carton-row-3"}>
                    <div className="carton-column carton-column-b" data-salio={status[3-1]}>{bolasArr[3-1]}</div>
                    <div className="carton-column carton-column-i" data-salio={status[8-1]}>{bolasArr[8-1]}</div>
                    <div className="carton-column carton-column-n" data-salio={true}></div>
                    <div className="carton-column carton-column-g" data-salio={status[18-1]}>{bolasArr[18-1]}</div>
                    <div className="carton-column carton-column-o" data-salio={status[23-1]}>{bolasArr[23-1]}</div>
                </div>
                <div className={Ganador?"carton-row carton-row-4  winner":"carton-row carton-row-4"}>
                    <div className="carton-column carton-column-b" data-salio={status[4-1]}>{bolasArr[4-1]}</div>
                    <div className="carton-column carton-column-i" data-salio={status[9-1]}>{bolasArr[9-1]}</div>
                    <div className="carton-column carton-column-n" data-salio={status[14-1]}>{bolasArr[14-1]}</div>
                    <div className="carton-column carton-column-g" data-salio={status[19-1]}>{bolasArr[19-1]}</div>
                    <div className="carton-column carton-column-o" data-salio={status[24-1]}>{bolasArr[24-1]}</div>
                </div>
                <div className={Ganador?"carton-row carton-row-5  winner":"carton-row carton-row-5"}>
                    <div className="carton-column carton-column-b" data-salio={status[5-1]}>{bolasArr[5-1]}</div>
                    <div className="carton-column carton-column-i" data-salio={status[10-1]}>{bolasArr[10-1]}</div>
                    <div className="carton-column carton-column-n" data-salio={status[15-1]}>{bolasArr[15-1]}</div>
                    <div className="carton-column carton-column-g" data-salio={status[20-1]}>{bolasArr[20-1]}</div>
                    <div className="carton-column carton-column-o" data-salio={status[25-1]}>{bolasArr[25-1]}</div>
                </div>
                
            </>)
        }
        
        return template;
    } 
   
    const LoadCartones = ()=>{     
        const SortCartones =  Cartones.sort((a,b)=>{
            const ItemA = a.serial.replaceAll(Sorteo,'')
            const ItemB = b.serial.replaceAll(Sorteo,'')
            if(Order){
                if(Order == 'asc'){
                    return parseInt(ItemA)-parseInt(ItemB)
                } 
                return parseInt(ItemB)-parseInt(ItemA)
            } 
            return parseInt(ItemB)-parseInt(ItemA)
        })
        return SortCartones?.map((e,index)=>{   
            return (  
                <CartonesAll  index={index} datos={ { serial:e?.serial.replace(Sorteo,""), dueno:e?.nombre_cliente, numeros:e?.datos }}
                    key={'carton-serial-'+e?.serial+Math.random(1000000000005465)}/>  
            ) 
        })
        
    }
    
    const DeleteCarton = (nro) =>{
        SaveCarton(CartonesSaved.filter((a)=> parseInt(a) != parseInt(nro)))
    } 
    const isWinner = (statusBolas) =>{
        let winner = true
        let Lleno = statusBolas.filter((a)=>a==false)
        if(Lleno.length > 0) winner = false
        return winner
    } 
    const CartonNew = ({showPaid,footerName,datos,hideLogo,index}) => {
        let Formateado = datos?.numeros.replaceAll("[","")
        Formateado = Formateado.replaceAll("]","")
        let statusBolas = [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];        
        let numeros = Formateado.split(",")
        const pagado = 1 
        const status = "activo"
        const nro = datos?.serial
        const nroCarton = nro.replaceAll(Sorteo,"")
        const propietario = datos?.dueno
        const altura = hideLogo ? "230px" : "230px" 
        const last_ball = Bolas[Bolas?.length - 1]
        if(Bolas && Bolas?.length > 0){
            Bolas.map((bolita)=>{
                numeros.map((numero,index)=>{
                    if(parseInt(bolita) == parseInt(numero)){
                        statusBolas[index] = true
                    }
                    if(index == 12)statusBolas[index] = true
                })
            })
        }
        const winner = isWinner(statusBolas);
        
        const isWaterMark = marcaAgua ? "carton_container_especial marcaAgua" : "carton_container_especial" 
        const claseContainerCss = winner ? "carton_container_winner" : isWaterMark
        const [selected,setSelected] = useState(false)
        const handlerSelectCarton = (e) =>{
            //handlerSelect(e)
            //setSelected(!e.target.dataset.selected) 
        }

        return (<>

           
                <div className={listMode ? "carton_individual  list-mode item" : "carton_individual  icon-mode item"} >
                    <div  className={claseContainerCss} style={{height: altura}}>
                        {
                            !hideLogo && 
                            <div>
                                <img src={"./images/especial/logo-bingove-especial.png"} height={120}  width={100}/>                
                            </div>
                        }
                        {
                            Ribbon &&
                            <div onClick={()=>{}} data-serial={nro} data-selected={selected} className={`carton_ribbon`}>
                                <span className={`${Ribbon?.color}`}><b> {Ribbon?.texto} </b></span>
                            </div>
                        }
                        <div className="carton_head bg-black text-sm">
                            <span>Carton {nroCarton}</span>
                            {
                                !noDelete 
                                && 
                                <FontAwesomeIcon 
                                    icon={faTrash} 
                                    onClick={()=>DeleteCarton(datos.serial)}
                                />
                            }
                        </div>
                        <div  className="carton_sorteo" >
                            <span className="carton_sorteo_span">
                                {footerName ? Sorteo : propietario}
                            </span>                        
                        </div>
                        {
                            !listMode &&
                            <div className="carton_content_grid ">
                            {
                                numeros.map((numero,index)=>{
                                    
                                    if(index == 12){
                                        return (<span className="carton_number_free" key={'carton-tabla-' + index}>
                                            LIBRE
                                        </span>)
                                    }
                                    if(winner){
                                        return (<span className="carton_number_winner" key={'carton-tabla-' + index}>
                                            {numero}
                                        </span>)
                                    }
                                    if(parseInt(numero) == parseInt(last_ball)){
                                        return (<span className="carton_number_active last_ball" key={'carton-tabla-' + index}>
                                            {numero}
                                        </span>)
                                    }
                                    if(statusBolas[index] == true){
                                        return (<span className="carton_number_active" key={'carton-tabla-' + index}>
                                            {numero}
                                        </span>)
                                    } else {
                                        return (<span className="carton_number_normal" key={'carton-tabla-' + index}>
                                            {numero}
                                        </span>)
                                    }

                                })
                            }                        
                            </div>
                        }
                        <div>
                        
                        </div>                  
                    </div>
                    {
                        footerName &&  !HideName &&           
                        <div className="carton_footer" >
                            <span>{propietario}</span>
                        </div>
                    }
                </div>
                    
        </>)
    }
    const CartonesAll = ({datos,index}) =>{ 
        return <CartonNew index={index} datos={datos} footerName={FooterName} hideLogo={false}/>  
    }
    return (<>
    {LoadCartones()}
    </>);
  }
  
  export default CartonEspecial;
