import React, {   useEffect,   useState } from "react";
import api from "../../services/api";
import {Header,Footer } from '../../components';
import './Faqs.css'; 
import 'react-tabs/style/react-tabs.css';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
function Faqs() {
    const location = useLocation();
    //todo para  configuraciones

    const [configs, setConfigs] = useState([]);
    const [link, setLink] = useState();
    const [vendidos,setVendidos] = useState();
    const [TotalRepartir, setTotalRepartir] = useState(0)
    const [sorteoActual, setSorteoActual] = useState();
    const [historial, setHistorial] = useState(false);
    const [PremiosLittle, setPremiosLittle] = useState(0)
    const [PremiosBig, setPremiosBig] = useState(0)
    const [videoHome1, setVideoHome1] = useState({})
    const [videoHome2, setVideoHome2] = useState({})
    const [videoUltimo, setVideoUltimo] = useState({})
    const [publicidad, setPublicidad] = useState(0)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState();
    const [hora, setHora] = useState();
    const [rondas, setRondas] = useState(1);
    const [precioCarton, setPrecioCarton] = useState(1);
    const [ventasStatus, setVentasStatus] = useState(false);
    const [promotorStatus, setPromotorStatus] = useState(false);
    const [promotorEmpleo, setPromotorEmpleo] = useState(false); 
    const [adminStatus, setAdminStatus] = useState(false);
    //####################### 
    const [Faqs,setFaqs] = useState([]);

    const GetAllConfigs = async () =>{
        await  api.get('/read/configs')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempConfigs = response?.data?.content
                 setConfigs(TempConfigs);
                 TempConfigs.map((item)=>{               
                     const {id,tipo,valor,extra} = item     
                     switch(tipo){ 
                         case 'sorteo_actual':  
                             setSorteoActual(valor) 
                             break;
                         case 'sorteo_youtube':  
                             setLink(valor)
                             break; 
                         case 'sorteo_fecha':  
                             setFecha(valor)
                             break;
                         case 'sorteo_hora':  
                             setHora(valor)
                             break;
                         case 'sorteo_dia':  
                             setDia(valor)
                             break;
                         case 'ventas_status':  
                             setVentasStatus(valor=='1')
                             break;
                         case 'promotores_status':  
                             setPromotorStatus(valor=='1')
                             break;
                         case 'administradores_status':  
                             setAdminStatus(valor=='1')
                             break;
                         case 'sorteo_rondas':  
                             setRondas(valor)
                             break;
                         case 'premio_base_small':  
                             setPremiosLittle(parseFloat(valor))
                             break;
                         case 'premio_base_big':  
                             setPremiosBig(parseFloat(valor))
                             break;
                         case 'premio_base_total':  
                             setTotalRepartir(parseFloat(valor))
                             break; 
                         case 'cartones_precio':  
                             setPrecioCarton(parseFloat(valor));
                             break;
                         case 'promotores_empleo':  
                             setPromotorEmpleo(valor=='1')
                             break; 
                         case 'video_home_1':  
                             setVideoHome1(item)
                             break; 
                         case 'video_home_2':  
                             setVideoHome2(item)
                             break; 
                         case 'video_ultimo_sorteo':  
                             setVideoUltimo(item)
                             break;
                         case 'cartones_vendidos':  
                             setVendidos(parseInt(valor))
                             break;
                         case 'precio_publicidad':  
                             setPublicidad(parseInt(valor))
                             break;
                         case 'home_historial':  
                             setHistorial(valor=='1')
                             break;
                             //cartones_vendidos
                         default :  break;
                     }  
 
 
                 })
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       }); 
     }
    const GetFaqs =  async () =>{
        await  api.get('/read/faqs')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempFaqs = response?.data?.content  
                 setFaqs(TempFaqs)
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       });
    }  
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);

    useEffect( () => {    
        GetAllConfigs()      
        GetFaqs()
    }, []);   
 
    const AcordionHeader = ({item,title}) =>{
        return (
            <div className="p-1 min-h-[50px] flex items-center" >
                <span className="text-[white] text-[16px]">{title}</span>
            </div>
        )
    } 
    const AcordionBody = ({value})=>{
        return (
            <div className="border-solid border-[purple] border-1 p-2">
                <span className="text-[black] text-[16px]">
                    {value}
                </span>
            </div>
        )
    }
    const TablaFaqs= () =>{
       
        return (<Accordion className="w-full  flex gap-x-3 flex-wrap justify-center ">
            {
                Faqs?.map((item,index)=>{

                    return (
                         
                        <Accordion.Item eventKey={item?.indice}  className="w-full lg:w-3/12" key={'acordion-faqs-' + index}>
                            <Accordion.Header >
                                <AcordionHeader title={`#${index + 1} | ${item?.pregunta}` }  />
                            </Accordion.Header>
                            <Accordion.Body>
                                <AcordionBody value={item?.respuesta} />
                            </Accordion.Body>
                        </Accordion.Item>     
                    )
                })          
            }
        </Accordion>)
    } 

    return (
        <div className="pages-sorteo">
            <Header noLogin={true} empleo={promotorEmpleo}/>
            <div className="p-[50px] flex w-full justify-center items-center bg-white flex-col">
                <h2 className="mt-5 mb-10">Preguntas Frecuentes</h2>
                <TablaFaqs  />
            </div>
            <Footer noLogin={true} />
        </div>
      
    );
  }
  export default Faqs;
