import React, { useCallback, useEffect, useRef, useState, version } from "react";
import api from "../../services/api";
import {Header,Footer,Youtube,Carton,Tablero,Chat,Loader} from '../../components';
import './Carrito.css';
import { isMobile } from "react-device-detect";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

function Carrito() {
    const location = useLocation();
    const [Cartones,setCartones] = useState([]);      
    const [CartonesLocal,setCartonesLocal] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [Orden,setOrden] = useState("asc")
    const [listMode,setListMode] = useState(false); 
    const [sorteoActual, setSorteoActual] = useState(null);  
    const [solicitado, setSolicitado] = useState(false);  

    const GetAllConfigs = async () =>{ 
        await api.get('/read/sorteo_actual')
         .then((response) =>{ 
            const DataResponse = response?.data?.content
             if(DataResponse?.length > 0){ 
                 setSorteoActual(DataResponse?.[0]?.valor)
             } 
          })
       .catch((err) => { console.error("error al llamar cartones: " + err)})
     } 
    const GetCartones = async()=>{
        if(sorteoActual == null) return  
        if(!solicitado){
            await  api.get('/read/sorteo_cartones_free')
             .then((response) =>{ 
                 if(response?.data?.content.length > 0){
                     const TempCartones= response?.data?.content   
                     setCartones(TempCartones) 
                 } 
              })
           .catch((err) => { console.error("error al llamar cartones: " + err)})
        } 
        setSolicitado(true)
        setIsLoading(false)
    }   

    const OnSelectCarton = (e) => { 
        const SerialToAdd = e.target.dataset.serial 
        const Selected = e.target.dataset.selected 
        const filtrado = CartonesLocal.filter((a)=> a != SerialToAdd)
        setCartonesLocal([...filtrado,SerialToAdd])
        console.log(CartonesLocal) 
        console.log(Selected) 
    } 


    useEffect( () => {      
        GetAllConfigs()
    }, []);
    useEffect( () => {    
        GetCartones() 
    }, [sorteoActual]);

    const handlerOrden = async ()=>{  
        if(Orden=="asc"){
           setOrden("desc") 
        } else {
           setOrden("asc") 
        } 
    }
    const handlerLista = ()=>{  
        setListMode(!listMode)  
    }

    const HeaderPanel = () =>{ 
        return (<>
        
        <div className="flex p-2 font-bold items-center justify-between gap-x-[10px] mt-[70px] lg:mt-0"> 

            {/*
                <div className="flex p-2 gap-x-[10px] ">
                    <span className="flex cursor-pointer p-2 pl-4 pr-4 bg-[#2c98d3] rounded rounded-[5px]" onClick={handlerLista}>
                        <b >{listMode ?"Completo":"Lista"}</b>  
                    </span>
                    <span className="flex cursor-pointer p-2 bg-[#2c98d3] rounded rounded-[5px]" onClick={handlerOrden}>
                        <b >{Orden=="asc"?"Ascendente":"Descendente"}</b>  
                    </span>
                </div>*/
            }
        </div>
        
        </>)
    }
    return (
        <div className="pages-cartones" style={{color:"white"}}>
            {
                isLoading
                && 
                <Loader />
            }
            <Header noLogin={true} />
            <HeaderPanel />
            <div className="page-content">
                <div className="flex flex-wrap row-principal cartones-row">
                    <Carton  
                        Sorteo={sorteoActual} 
                        Cartones={Cartones}
                        Order={Orden}  
                        listMode={listMode}
                        Ribbon={{color:"geen_mode",texto:"LIBRE"}}
                        Bolas={[]}
                        HideName
                        CartonesSaved={[]}
                        FooterName
                        handlerSelect={OnSelectCarton}
                    />          
                </div>
            </div>
            <Footer noLogin={true} />
        </div> 
    );
  }
  export default Carrito;
