import React, { useEffect, useState } from "react";
import api from "../../services/api";
import '../../App.css';
import './Tablero.css';
import Iframe from 'react-iframe'
function Tablero({datos,funcion,Bolas}) {
    

    const Salieron=()=>{
         
        return Bolas
            .slice(0)
            .reverse()
            .map(( e, i)=>
            (<div 
                className={ i == 0?"bolita ultima":"bolita "} 
                data-number={e}  
                key={'salieron-tabla-' + i}>
                {e}
            </div>)
        )        
    }
    return (
        <div  className="flex gap-x-[2px] justify-start overflow-x-scroll overflow-y-hidden w-[100vw]" >
            <div className="flex justify-start items-start ">
                <Salieron />
            </div>
        </div>
      
    );
  }
  
  export default Tablero;