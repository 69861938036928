import React, { useCallback, useEffect, useRef, useState, version } from "react";
import api from "../../services/api";
import {Header,Footer,Youtube,Carton,Tablero,Chat} from '../../components';
import './Sorteo.css';
import Select , { components } from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faExpand, faMinimize, faStar} from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import { isMobile } from "react-device-detect";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
function Sorteo() {
    const location = useLocation();
    const CARTONSAVED = window.localStorage.getItem('cartonesSaved')

    const [configs, setConfigs] = useState([]);
    const [link, setLink] = useState();
    const [vendidos,setVendidos] = useState();
    const [TotalRepartir, setTotalRepartir] = useState(0)
    const [sorteoActual, setSorteoActual] = useState();
    const [historial, setHistorial] = useState(false);
    const [PremiosLittle, setPremiosLittle] = useState(0)
    const [PremiosBig, setPremiosBig] = useState(0)
    const [videoHome1, setVideoHome1] = useState({})
    const [videoHome2, setVideoHome2] = useState({})
    const [videoUltimo, setVideoUltimo] = useState({})
    const [publicidad, setPublicidad] = useState(0)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState();
    const [hora, setHora] = useState();
    const [rondas, setRondas] = useState(1);
    const [precioCarton, setPrecioCarton] = useState(1);
    const [ventasStatus, setVentasStatus] = useState(false);
    const [promotorStatus, setPromotorStatus] = useState(false);
    const [promotorEmpleo, setPromotorEmpleo] = useState(false);
    const [adminStatus, setAdminStatus] = useState(false);








    const [Cartones,setCartones] = useState([]);
    const [serialToAdd,setSerialToAdd] = useState();      
    const [premioDiagonal,setPremioDiagonal] = useState(0);
    const [premioEsquinas,setPremioEsquinas] = useState(0);
    const [premioLleno,setPremioLleno] = useState(0);
    const [AppVersion,setAppVersion] = useState(0); 
    const [DiaSorteo,setDiaSorteo] = useState(0);
    const [Promotores,setPromotores] = useState(0); 
    const [youtubeExpand,setYoutubeExpand] = useState(false);
    
    const [rondaActual,setRondaActual] = useState(3);
    const [Ganadores_1,setGanadores_1] = useState([]);
    const [Ganadores_2,setGanadores_2] = useState([]);
    const [Ganadores_3,setGanadores_3] = useState([]);
    const [CartonesLocal,setCartonesLocal] = useState([])

    const [BolasSorteo,setBolasSorteo] = useState([]);
    const input_add = useRef(null)
 
    const GetAllConfigs = async () =>{
        await  api.get('/read/configs')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempConfigs = response?.data?.content
                 setConfigs(TempConfigs);
                 TempConfigs.map((item)=>{               
                     const {id,tipo,valor,extra} = item     
                     switch(tipo){ 
                         case 'sorteo_actual':  
                             setSorteoActual(valor) 
                             break;
                         case 'sorteo_youtube':  
                             setLink(valor)
                             break; 
                         case 'sorteo_fecha':  
                             setFecha(valor)
                             break;
                         case 'sorteo_hora':  
                             setHora(valor)
                             break;
                         case 'sorteo_dia':  
                             setDia(valor)
                             break;
                         case 'ventas_status':  
                             setVentasStatus(valor=='1')
                             break;
                         case 'promotores_status':  
                             setPromotorStatus(valor=='1')
                             break;
                         case 'administradores_status':  
                             setAdminStatus(valor=='1')
                             break;
                         case 'sorteo_rondas':  
                             setRondas(valor)
                             break;
                         case 'premio_base_small':  
                             setPremiosLittle(parseFloat(valor))
                             break;
                         case 'premio_base_big':  
                             setPremiosBig(parseFloat(valor))
                             break;
                         case 'premio_base_total':  
                             setTotalRepartir(parseFloat(valor))
                             break; 
                         case 'cartones_precio':  
                             setPrecioCarton(parseFloat(valor));
                             break;
                         case 'promotores_empleo':  
                             setPromotorEmpleo(valor=='1')
                             break; 
                         case 'video_home_1':  
                             setVideoHome1(item)
                             break; 
                         case 'video_home_2':  
                             setVideoHome2(item)
                             break; 
                         case 'video_ultimo_sorteo':  
                             setVideoUltimo(item)
                             break;
                         case 'cartones_vendidos':  
                             setVendidos(parseInt(valor))
                             break;
                         case 'precio_publicidad':  
                             setPublicidad(parseInt(valor))
                             break;
                         case 'home_historial':  
                             setHistorial(valor=='1')
                             break;
                             //cartones_vendidos
                         default :  break;
                     }  
 
 
                 })
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       });
       updateTotalRepartir()
     }
    const GetCartones = async()=>{
        await  api.get('/read/sorteo_cartones')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempCartones= response?.data?.content  
                 setCartones(TempCartones)
                 //console.log("TempCartones" ,TempCartones);
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       }); 
    }
    const GetBolasSorteo = async() =>{
        api.get('/read/sorteo_bolitas')
        .then((response) =>{ 
            if(response?.data?.content.length > 0){
                const TempData = response?.data?.content                
                const BolasParse = TempData[0]?.bolas?.split(",")
                setBolasSorteo(BolasParse);
            }
        })
        .catch((err) => console.error( err));
    }
    const GetSorteo = async () =>{       
        await  api.get('/read/sorteo_datos')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempSorteo = response?.data?.content  
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       });
    }
    const GetGanadores =  async () =>{
        await  api.get('/read/sorteo_datos')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempSorteo = response?.data?.content  
                 const GanadoresList1 = TempSorteo[0]?.ganadores_ronda_1
                 const GanadoresList2 = TempSorteo[0]?.ganadores_ronda_2
                 const GanadoresList3 = TempSorteo[0]?.ganadores_ronda_3
                 if(GanadoresList1){setGanadores_1(GanadoresList1?.split('-'))}
                 if(GanadoresList2){setGanadores_2(GanadoresList2?.split('-'))}
                 if(GanadoresList3){setGanadores_3(GanadoresList3?.split('-'))}
                 console.log("TempSorteo",TempSorteo)
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       });
    } 

    const updateTotalRepartir = async() =>{
        const totalRecolectado = (parseInt(vendidos) * 0.80 ) - publicidad
        let temp_rondas = rondas 
        if( totalRecolectado > TotalRepartir){
            const Little2 = totalRecolectado / (temp_rondas * 8)
            const Little3 = totalRecolectado / (3 * 8)
            setTotalRepartir(totalRecolectado )
            if(Little3 >= PremiosLittle){
                setRondas(3)
                setPremiosLittle(Little3) 
                setPremiosBig(Little3 * 2)
            } else {
                setPremiosLittle(Little2) 
                setPremiosBig(Little2 * 2)
            }
            //console.log('se recolecto mas' + totalRecolectado)  
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, [location]);
      
    useEffect( () => {    
        if(CARTONSAVED != undefined && CARTONSAVED != null){
            setCartonesLocal(CARTONSAVED.split(","))
        }
        GetAllConfigs()
        GetCartones()     
        GetBolasSorteo()  
        const interval = setInterval(GetBolasSorteo, 5000);
        return () => clearInterval(interval);

    }, []);

    useEffect( () => {    
        GetGanadores()            
        const interval = setInterval(()=>{ 
            GetGanadores()
        }, 60000);
        return () => clearInterval(interval);
    }, []);  

    useEffect( () => {        
        GetCartones()
    }, [sorteoActual]);  

    useEffect( () => { 
        const timeout = setTimeout(() => {
            updateTotalRepartir()
        }, 60000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect( () => {
        updateTotalRepartir() 
    }, [vendidos,TotalRepartir,PremiosBig,PremiosLittle]);

    const expandbeHandler = () =>{
        setYoutubeExpand(!youtubeExpand)
    }
    const Premio = ({name = "Esquinas",premio = 0,size = "40",solid}) =>{
        let size_premio = size + "px"
        let size_title = "12px"
        let widthPremio = "140px"
        switch(size){
            case "small" : widthPremio = "140px" ; size_premio = 20 ; size_title = 12 ; break ;
            case "medium" : widthPremio = "250px" ; size_premio = 40 ; size_title = 18 ; break ;
            case "large" : widthPremio = "140px" ; size_premio = 180 ; size_title = 90 ; break ;
            default : break ;

        }
        const bg_gradient = solid? "#7f2e93":"linear-gradient(0deg,#7f2e93 0%,#7f2e93 20%,#00000000 100%)"
        return (<>
            <div style={
                {
                    background: bg_gradient,
                    height:(size_premio + 20  ) + "px" ,
                    width: widthPremio,
                    display:"flex",
                    alignItems:"center",
                    borderRadius:90,
                    justifyContent:"center"
                }}>
                <span style={
                    {
                        fontSize:12,
                        textAlign:"center",
                        display:"flex",
                        flexDirection:"column",
                        paddingTop:"5px",
                        paddingBottom:"5px",
                        lineHeight:  (size_title + 10) + "px"
                    }}>
                    <b style={{color:"white",fontSize: size_title + "px",marginTop:(-size_title -5 )+ "px"}}>{name}</b>
                    <b style={{color:"#ffcc00",fontSize: size_premio + "px"}}>$ {premio?.toFixed(2)}</b>
                </span>
            </div>
        </>)
    }
    const TablaLateral = () =>{
        return(
            <div className="div-tabla-detalles detalles">
                <img  src="./images/logo_bingove_01.png" width={150} height={80} alt="" />
                <br />       
                <div className="div-premios" style={{width:300,height:270}}>
                    <div className="tabla-premios"  style={{alignItems:"center", display:"flex",flexDirection:"column",gap:"15px"}}>
                        <div style={{display:"flex",flexDirection:"column",gap:"10px"}}>
                            <div style={{gap:"5px",display:"flex",flexDirection:"row"}}>
                                <Premio size="small" name="Esquinas" premio={PremiosLittle} solid/>
                                <Premio size="small" name="Diagonal" premio={PremiosLittle} solid/>
                            </div>
                            <div style={{gap:"5px",display:"flex",flexDirection:"row"}}>
                                <Premio size="small" name="Diagonal Invetido" premio={PremiosLittle} solid/>
                                <Premio size="small" name="Cruz" premio={PremiosLittle} solid/>
                            </div>
                            <div style={{gap:"5px",display:"flex",flexDirection:"row"}}>
                                <Premio size="small" name="Linea Horizontal" premio={PremiosLittle} solid/>
                                <Premio size="small" name="Linea Vertical" premio={PremiosLittle} solid/>
                            </div>
                        </div>
                        <Premio size="medium" name="Carton Lleno" premio={PremiosBig} solid/>
                        {ventasStatus == '0' && <b style={{color:"red"}}>{"VENTAS CERRADAS"}</b>}
                        {ventasStatus == '1' && <b style={{color:"green"}}>{"VENTAS ABIERTAS"}</b>}
                    </div>
                </div>
            </div>
        )
    }
    const AcordionHeaderBody = ()=>{
        return (<div className="accordion-titulo">
            <span className="accordion-titulo-carton">CARTON</span>
            <span className="accordion-titulo-nombre">NOMBRE GANADOR</span>
            <span className="accordion-titulo-premio">PREMIO</span>
        </div>)
    }
    const AcordionHeaderTab = ({item,title}) =>{
        return (<div className="tab-acordion" style={{}}>
            <span>{title}</span>
            <span>{item.length} { item.length == 1 ? "GANADOR":"GANADORES"}</span>
        </div>)
    }
    const AcordionGanador = ({item,premio,inicial})=>{
        return item?.map((ganador,index)=>{
            var contenidoParentesis = ganador.match(/\(([^)]+)\)/);
            var winner = contenidoParentesis ? contenidoParentesis[1] : null;            
            var match = null;
            switch(inicial) {
                case "L1": match = ganador.match(/L. Horizontal Nro: (\d{4})/);break;
                case "L2": match = ganador.match(/L. Vertical Nro: (\d{4})/);break;
                case "E": match = ganador.match(/ESQUINAS Nro: (\d{4})/);break;
                case "D": match = ganador.match(/DIAGONAL Nro: (\d{4})/);break;
                case "DI": match = ganador.match(/DIAGONAL INV Nro: (\d{4})/);break;
                case "C": match = ganador.match(/CRUZ Nro: (\d{4})/);break;
                case "LL": match = ganador.match(/LLENO  Nro: (\d{4})/);break;

                default : match = ganador.match(/Linea Nro: (\d{4})/);break;
            }
            
            var numeroCarton = match ? match[1] : null;
            if (!numeroCarton) {
                match = ganador.match(/(\d{4})/);
                numeroCarton = match ? match[1] : null;
            }
            let numeral = numeroCarton;
            let premioActual = premio / item.length
            return (
                <div className="accordion-contenido" key={'ganadores-acordion-' + index}>
                    <span className="accordion-contenido-carton">#{numeral}</span>
                    <span className="accordion-contenido-nombre">{winner}</span>
                    <span className="accordion-contenido-premio">${premioActual.toFixed(2)}</span>
                </div>
            )
        })
    }
    const TablaGanadores= () =>{
        const rondas = ["RONDA 1","RONDA 2","RONDA 3"]
        return(<>
            <div className="div-tabla-detalles ganadores z-[99]">
            <div className="nro-sorteo" style={{fontSize:18, gap:5,display:"flex",flexDirection:"column",alignItems:"center"}}>
                <b>SORTEO {sorteoActual}</b>
            </div>
            <div className="div-ganadores" style={{gap:5,display:"flex",flexDirection:"column",alignItems:"center"}}>
                <span className="ganador-tittle" style={{textAlign:"center"}}>
                    <b>LISTA DE GANADORES</b>
                </span>                
                <div className="tabla-ganadores" style={{minHeight:"290px",display:"block"}}>
                    <Tabs>
                        <TabList>
                            {rondas.map((ronda,index)=>{
                                return (<Tab key={'ganadores-head-' + index}>
                                    <span style={{fontSize:14}} >{ronda}</span> 
                                </Tab>)
                            })}
                        </TabList>
                        {rondas.map((ronda,index)=>{
                            let Gana = Ganadores_1
                            switch(index){
                                case 0: Gana = Ganadores_1 ; break ;
                                case 1: Gana = Ganadores_2 ; break ;
                                case 2: Gana = Ganadores_3 ; break ;
                                default: break ;
                            } 
                            const ganadoresLinealH = Gana?.filter((a) => a.includes("L. Horizontal Nro:") )
                            const ganadoresLinealV = Gana?.filter((a) => a.includes("L. Vertical Nro:") )
                            const ganadoresEsquinas = Gana?.filter((a) => a.includes("ESQUINAS Nro:") )
                            const ganadoresDiagonal = Gana?.filter((a) => a.includes("DIAGONAL Nro:") )
                            const ganadoresDiagonalInv = Gana?.filter((a) => a.includes("DIAGONAL INV Nro:") )
                            const ganadoresCruz = Gana?.filter((a) => a.includes("CRUZ Nro:") )
                            const ganadoresLleno = Gana?.filter((a) => a.includes("LLENO  Nro:") )
                            return (<TabPanel key={'ganadores-tabla-' + index}> 
                                    <div style={{maxHeight:"250px",overflowY:"visible",overflowX:"hidden"}}>
                                    <Accordion defaultActiveKey={"0"}>
                                        <Accordion.Item eventKey="0"  >
                                            <Accordion.Header >
                                               <AcordionHeaderTab title="LINEA HORIZONTAL" item={ganadoresLinealH}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresLinealH} premio={PremiosLittle}  inicial="L1" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1"  >
                                            <Accordion.Header >
                                               <AcordionHeaderTab title="LINEA VERTICAL" item={ganadoresLinealV}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresLinealV} premio={PremiosLittle}  inicial="L2" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2"  >
                                            <Accordion.Header >
                                               <AcordionHeaderTab title="DIAGONAL" item={ganadoresDiagonal}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresDiagonal} premio={PremiosLittle} inicial="D" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3"  >
                                            <Accordion.Header >
                                               <AcordionHeaderTab title="DIAGONAL INV." item={ganadoresDiagonalInv}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresDiagonalInv} premio={PremiosLittle}  inicial="DI" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4"  >
                                            <Accordion.Header >
                                               <AcordionHeaderTab title="CRUZ GRANDE" item={ganadoresCruz}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresCruz} premio={PremiosLittle} inicial="C" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5"  >
                                            <Accordion.Header >
                                               <AcordionHeaderTab title="4 ESQUINAS" item={ganadoresEsquinas}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresEsquinas} premio={PremiosLittle} inicial="E" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6"  >
                                            <Accordion.Header >
                                               <AcordionHeaderTab title="CARTON LLENO" item={ganadoresLleno}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresLleno} premio={PremiosBig}  inicial="LL" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    </div>
                                </TabPanel>
                            )
                        })}
                        
                    </Tabs>
                </div>
            </div>
        </div>
        </>)
    }
    const handleChange = ()=>{
        setSerialToAdd(input_add.current.value);
    }
    const UpdateCartonesLocal = (value = []) =>{
        localStorage.setItem('cartonesSaved',value)
        setCartonesLocal(value)
    }
    const CartonAdd = () =>{
        return (<>
            <div className="w-full flex gap-x-[20px] bg-[#55bb99] 
                p-2 justify-center h-[50px] items-center" 
                
                >
                {!isMobile && <span>AGREGAR CARTON</span>}
                <input 
                    className="p-2 text-[black]"
                    ref={input_add}
                    type="number"  
                    placeholder="Serial de tu carton" 
                    onChange={handleChange}
                />
                <button className="bg-[#7f2e93] p-2 rounded rounded-[5px]"
                    onClick={()=>{                   
                    let validar = false;                    
                    Cartones?.map((e)=>{
                        const serial = (e.serial).replace(sorteoActual,'')
                        if( parseInt(serial) == parseInt(serialToAdd)){
                            UpdateCartonesLocal([...CartonesLocal.filter((a)=> parseInt(a) != parseInt(serialToAdd)),serialToAdd])                              
                            validar=true;
                        }                        
                    })
                    if(!validar){
                        alert('el carton no es valido para este sorteo o no fue vendido aun');
                    } else {
                        input_add.current.value = ''
                    }
                }}
                >AGREGAR</button>
            </div>
        </>)
    }
    return (
        <div className="pages-sorteo" style={{color:"white"}}>
            <Header noLogin={true} empleo={promotorEmpleo}/>

            <div className="page-content">
                <div className="row-1 row-principal flex justify-center items-start">

                    {   !youtubeExpand && !isMobile &&
                        <div className="column-1 column-principal z-10 " >
                            <TablaLateral  />
                        </div>
                    }

                    <div className="z-[9999]  top-[50px] fixed lg:relative lg:top-[0px]  column-2 column-principal flex flex-col justify-center" >
                        <div className="" style={{display:"flex",background:"black",padding:10,justifyContent:"space-between",alignItems:"center"}}>
                            <span><b> Rondas : {rondas}</b> </span>
                            <span><b>{`${dia} ${fecha}`}</b></span>
                            {
                                !youtubeExpand && 
                                <FontAwesomeIcon icon={faExpand} onClick={expandbeHandler} />
                            }
                            {
                                youtubeExpand && 
                                <FontAwesomeIcon icon={faMinimize} onClick={expandbeHandler} />
                            }
                        </div>
                         
                        <Youtube link={link} expand={youtubeExpand} />
                        
                        {
                            isMobile && 
                            <div className="bg-[black] flex p-1 overflow-scroll " style={{zIndex:10010}}>
                                <Tablero Bolas={BolasSorteo}/>
                            </div>
                        }
                    </div>
                    {
                        !youtubeExpand && 
                        <div className="column-3 column-principal mt-0 lg:mt-[350px] w-full flex justify-center lg:mt-[0px]" 
                            style={{zIndex:9}}>
                            {TablaGanadores()}
                        </div>
                    }
                </div>
                {
                    !isMobile && 

                    <div className="bg-[black] flex justify-start p-1" >
                        <Tablero Bolas={BolasSorteo}/>
                    </div>
                }
                { 
                    isMobile && 
                    CartonAdd()
                }

                <div className="row-2 flex flex-wrap gap-x-[5px]">
                    { !isMobile &&  CartonAdd()}
                    <Carton 
                        Bolas={BolasSorteo}
                        CartonesSaved={CartonesLocal}
                        SaveCarton={UpdateCartonesLocal}
                        Sorteo={sorteoActual}
                        Cartones={Cartones}
                        serialToAdd={serialToAdd} 
                    />          
                </div>
            </div>
            <Footer noLogin={true} />
        </div>
      
    );
  }
  export default Sorteo;
