import React, { useEffect, useState } from "react";
import api from "../../services/api";
import {Header,Footer} from '../../components';
import './Promotores.css';
import Select , { components } from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {Link } from 'react-router-dom';
import Iframe from 'react-iframe'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {isMobile } from 'react-device-detect';
import { Paises } from "../../helpers/Constants";
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom'; 
import ReactGA from 'react-ga';

function Promotores() {
    const location = useLocation();
    //const [promotores,setPromotores] = useState([])
    const [banner,setBanner] = useState(true);
    const [promotores,setPromotores] = useState([]);
    const [currentStep,setCurrentStep]= useState(1)
    const [currentPais,setCurrentPais] = useState(0)
    const [currentZona,setCurrentZona] = useState(0)
    const [currentPromotor,setCurrentPromotor] = useState(0)
    const [currentCountry,setCurrentCountry] = useState({})
    const [currentState,setCurrentState] = useState({})
    const [currentCities,setCurrentCities] = useState({})
    
    //nueva estrategia 
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedPromotor, setSelectedPromotor] = useState(null);
    const [filteredPais,setFilteredPais] = useState(Paises); 
    const [filteredPromotores,setFilteredPromotores] = useState([]); 

    const FiltrarPromotores = () => {

        const filtered = promotores.filter(promotor => {
            //return true
            const ciudadPromotor = promotor?.ciudad?.toLowerCase()
            const Pais = selectedCountry?.nombre?.toLowerCase()
            const Estado = selectedProvince?.nombre?.toLowerCase()
            const Ciudad = selectedCity?.nombre?.toLowerCase()
            //console.log("ciudadPromotor",ciudadPromotor)
            //console.log("Ciudad",Ciudad)
            //console.log("%c##############","color:green") 
            if(Pais != 'venezuela'){
                return  ciudadPromotor.includes(Ciudad) 
                    && ciudadPromotor.includes(Estado)
                    && ciudadPromotor.includes(Pais)
            } else {
                return    ciudadPromotor.includes(Estado) && ciudadPromotor.includes(Ciudad)
            }

            return (
              (!selectedCountry || promotor.ciudad.includes(selectedCountry?.nombre)) &&
              (!selectedProvince || promotor.ciudad.includes(selectedProvince?.nombre)) &&
              (!selectedCity || promotor.ciudad.includes(selectedCity?.nombre))
            );
        }) || [];
        setFilteredPromotores(filtered);
    }
    const FiltrarEstados = () =>{     
        if(promotores.length > 0){ 
            //console.log(promotores)
            const EstadosFinal = Paises[0]?.estados?.filter((estado)=>{ 
                const NombreEstado = estado?.nombre.toLowerCase()                
                //console.log(promotores)
                for(let x = 0 ; x < promotores.length ; x++){
                    const promotorActual = promotores[x].ciudad.toLowerCase()
                    if(promotorActual.includes(NombreEstado)){ 
                        return true;  
                    }
                }   
            })     
            Paises[0].estados = EstadosFinal  

            setFilteredPais(Paises)   
        }
    } 
     
  

    //todo para  configuraciones
    const [configs, setConfigs] = useState([]);
    const [link, setLink] = useState();
    const [vendidos,setVendidos] = useState();
    const [TotalRepartir, setTotalRepartir] = useState(0)
    const [sorteoActual, setSorteoActual] = useState();
    const [historial, setHistorial] = useState(false);
    const [PremiosLittle, setPremiosLittle] = useState(0)
    const [PremiosBig, setPremiosBig] = useState(0)
    const [videoHome1, setVideoHome1] = useState({})
    const [videoHome2, setVideoHome2] = useState({})
    const [videoUltimo, setVideoUltimo] = useState({})
    const [publicidad, setPublicidad] = useState(0)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState();
    const [hora, setHora] = useState();
    const [rondas, setRondas] = useState(1);
    const [precioCarton, setPrecioCarton] = useState(1);
    const [ventasStatus, setVentasStatus] = useState(false);
    const [promotorStatus, setPromotorStatus] = useState(false);
    const [promotorEmpleo, setPromotorEmpleo] = useState(false); 
    const [adminStatus, setAdminStatus] = useState(false);
    //####################### 
    

    const GetAllConfigs = async () =>{
        await  api.get('/read/configs')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempConfigs = response?.data?.content
                 setConfigs(TempConfigs);
                 TempConfigs?.map((item)=>{               
                     const {id,tipo,valor,extra} = item     
                     switch(tipo){ 
                         case 'sorteo_actual':  
                             setSorteoActual(valor) 
                             break;
                         case 'sorteo_youtube':  
                             setLink(valor)
                             break; 
                         case 'sorteo_fecha':  
                             setFecha(valor)
                             break;
                         case 'sorteo_hora':  
                             setHora(valor)
                             break;
                         case 'sorteo_dia':  
                             setDia(valor)
                             break;
                         case 'ventas_status':  
                             setVentasStatus(valor=='1')
                             break;
                         case 'promotores_status':  
                             setPromotorStatus(valor=='1')
                             break;
                         case 'administradores_status':  
                             setAdminStatus(valor=='1')
                             break;
                         case 'sorteo_rondas':  
                             setRondas(valor)
                             break;
                         case 'premio_base_small':  
                             setPremiosLittle(parseFloat(valor))
                             break;
                         case 'premio_base_big':  
                             setPremiosBig(parseFloat(valor))
                             break;
                         case 'premio_base_total':  
                             setTotalRepartir(parseFloat(valor))
                             break; 
                         case 'cartones_precio':  
                             setPrecioCarton(parseFloat(valor));
                             break;
                         case 'promotores_empleo':  
                             setPromotorEmpleo(valor=='1')
                             break; 
                         case 'video_home_1':  
                             setVideoHome1(item)
                             break; 
                         case 'video_home_2':  
                             setVideoHome2(item)
                             break; 
                         case 'video_ultimo_sorteo':  
                             setVideoUltimo(item)
                             break;
                         case 'cartones_vendidos':  
                             setVendidos(parseInt(valor))
                             break;
                         case 'precio_publicidad':  
                             setPublicidad(parseInt(valor))
                             break;
                         case 'home_historial':  
                             setHistorial(valor=='1')
                             break;
                             //cartones_vendidos
                         default :  break;
                     }  
 
 
                 })
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       }); 
     }
    const GetPromotores =  async () =>{
        await  api.get('/read/promotores')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempPromotores = response?.data?.content  
                 setPromotores(TempPromotores)
                 //setPromotorList(TempPromotores)
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       });
    }  

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);
    useEffect( () => {    
        FiltrarPromotores() 
    }, [selectedCountry,selectedCity,selectedProvince]); 

    useEffect( () => {     
        FiltrarEstados()
    }, [promotores]);  

    useEffect( () => {    
        GetAllConfigs()      
        GetPromotores()
    }, []);  
  
    
    const CarrouselJs = (props) =>{
        const MobileHeight = isMobile? 600 : 550
        const MobileWidth = isMobile? 550 : 550
        return (<>
            <Carousel
                autoPlay={false}
                infiniteLoop={true}
                interval={5000}
                showArrows={true}
                dynamicHeight={false}
                stopOnHover={true}
                showThumbs={false}
                className="min-h-[600px] lg:min-h-[550px]"
            >
                
                <img 
                    className="object-fill h-[100%] w-full object-center" 
                    src={isMobile? "https://bingove.com/images/verifica_carton.jpg" : "https://bingove.com/images/banner_verifica.png"} 
                    height={MobileHeight} 
                />  
            </Carousel>
        </>)
    } 
    const StepsTemplate = ({texto,index,noIcon})=>{
        let bgStep = 'gray'
        let icono = 'bola_gris.png'
        let actived = currentStep == index
        let passed = currentStep > index
        if(actived){
            bgStep = '#8a35a5'
            icono = 'bola_morado.png'
        }
        if(passed){
            bgStep = '#009911'
            icono = 'bola_verde.png'
        }
        const urlIcon = `url('/images/${icono}')`
        const handlerMouseClick =  ()=> {
            if(currentStep > index){
                //setCurrentStep(index)
            }
           // setCurrentStep(index)
        }
        return (<>
            <div className="step-promotor" 
                style={
                    {
                        background:noIcon?"black":bgStep,
                        cursor:"pointer",
                        border:noIcon?`dashed 2px ${bgStep}`:''
                    }} 
                onClick={handlerMouseClick}>
                {!noIcon &&
                    <div 
                        className="step-icon" 
                        style={
                            {
                                background:urlIcon,
                                backgroundPosition: "center center",
                                backgroundSize: "contain"
                            }}
                    >
                        <span>{index}</span>
                    </div>
                }
                <span><b>{texto}</b> </span>
            </div>
        </>)
    }
 
    const ImagenPromotores = ({nombre,genero}) => {
        //const nombre = promotor?.nombre
        const initialState = false
        const [hovered, setHovered] = useState(initialState);
        const IconImageGray = `https://bingove.com/images/icons/${genero?.toLowerCase()}_icon_gray.png`
        const IconImageColor = `https://bingove.com/images/icons/${genero?.toLowerCase()}_icon_white.png` 
        console.log('filteredPromotores',filteredPromotores)
        const PromotorSelected = filteredPromotores.filter((a)=> a?.nombre == nombre)?.[0] 
        const handlerMouseEnter = () =>{
            setHovered(true)
        } 
        const handlerMouseLeave = () =>{             
            if(PromotorSelected != selectedPromotor){
                setHovered(false)
            } 
        }
        const handlerMouseClick= () =>{            
            if(PromotorSelected != selectedPromotor){
                setSelectedPromotor(PromotorSelected)
                setCurrentStep(5)
            } 
        }
        return (<>
            <div 
                className="paises-item h-[170px] w-[auto]" 
                onClick={handlerMouseClick} 
                onMouseEnter={handlerMouseEnter} 
                onMouseLeave={handlerMouseLeave}
                style={{border: hovered ? "solid 2px white" : "solid 2px #888"}}
            >
                 
                <img 
                    className="object-fit h-[100px] w-[100px] min-h-[100px] min-w-[100px]"
                    src={hovered ? IconImageColor : IconImageGray} 
                    alt="Imagen" 
                    height={100} 
                    width={100}
                /> 
                <span className="m-0" style={{color: hovered ? "white" : "#888"}}>
                    {nombre}
                </span>                 
             
                 
            </div>
            </>
        );
    };
    const ImagenPromotoresContacto = ({promotor}) => { 
        const {genero,nombre,telefono}= promotor
        const initialState = true
        const [hovered, setHovered] = useState(initialState);
        const IconImageGray = `https://bingove.com/images/icons/${genero?.toLowerCase()}_icon_gray.png`
        const IconImageColor = `https://bingove.com/images/icons/${genero?.toLowerCase()}_icon_white.png` 
        console.log('filteredPromotores',filteredPromotores)
        const PromotorSelected = filteredPromotores.filter((a)=> a?.nombre == nombre)?.[0] 
        const Telefono = telefono.replaceAll('+','')
        let TelefonoLocal = telefono
        if(selectedCountry?.nombre == 'Venezuela'){
            TelefonoLocal = telefono.replaceAll('+58','0')
        }
        const handlerMouseEnter = () =>{
            setHovered(true)
        } 
        const handlerMouseLeave = () =>{             
            if(PromotorSelected != selectedPromotor){
                //setHovered(false)
            } 
        }
        const handlerMouseClick= () =>{            
            if(PromotorSelected != selectedPromotor){
                setSelectedPromotor(PromotorSelected)
            } 
        }
        return (<>
            <div 
                className="h-[300px] max-w-[100vw] w-[500px] p-[20px] rounded flex gap-[20px] justify-center " 
                onClick={handlerMouseClick} 
                onMouseEnter={handlerMouseEnter} 
                onMouseLeave={handlerMouseLeave}
                
            >
                 
                <img 
                    className="object-fit h-[100px] w-[200px] min-h-[200px] min-w-[100px]"
                    src={hovered ? IconImageColor : IconImageGray} 
                    alt="Imagen" 
                    height={200} 
                    width={200}
                /> 
                <div className="flex flex-col text-[18px] gap-[15px]" >

                    <span className="m-0" style={{color: hovered ? "white" : "#888"}}>
                       <b>Nombre: </b>  <b>{nombre}</b> 
                    </span>        
                    <span className="m-0" style={{color: hovered ? "white" : "#888"}}>
                        <b>Telefono: </b>  <b>{TelefonoLocal}</b> 
                    </span>      
                    <span className="m-0" style={{color: hovered ? "white" : "#888"}}>
                        <b>Ciudad: </b> <b>{selectedCity?.nombre}</b> 
                    </span> 
                    {
                        !isMobile && 
                        <a className="no-underline text-white bg-[green] flex justify-center items-center p-[10px] font-bold rounded"
                        href={ `https://wa.me/${Telefono}?text=Hola+${nombre}%2C+Quisiera+saber+mas+de+BingoVe.` } target="_blank">
                            Escribir a WhatsApp
                        </a>        
                    }      
                </div> 
               { /*
                    <span style={{color: hovered ? "white" : "#888",marginTop:0}}>
                        {selectedCity?.nombre}
                    </span> 
                */}
                 
            </div>
            
            </>
        );
    };
    const ImagenPaises = ({icon,nombre}) => {
        const initialState = false
        const [hovered, setHovered] = useState(initialState);
        const IconImageGray = `https://bingove.com/images/icons/${icon}_icon_gray.png`
        const IconImageColor = `https://bingove.com/images/icons/${icon}_icon_color.png` 
        const PaisSelected = Paises.filter((a)=> a?.nombre == nombre )?.[0] 
        const handlerMouseEnter = () =>{
            setHovered(true)
        }
        const handlerMouseLeave = () =>{             
            if(PaisSelected !== selectedCountry){
                setHovered(false)
            } 
        }
        const handlerMouseClick= () =>{
           
            if(PaisSelected !== selectedCountry){
                setSelectedCountry(PaisSelected)
                setCurrentStep(2) 
            } 
        }
        return (<>
            <div 
                className="paises-item h-[170px] w-[150px]" 
                onClick={handlerMouseClick} 
                onMouseEnter={handlerMouseEnter} 
                onMouseLeave={handlerMouseLeave}
                style={{border: hovered ? "solid 2px white" : "solid 2px #888"}}
            >
                
                <img 
                    className="object-cover h-[100px] w-[100px] min-h-[100px] min-w-[100px]"
                    src={hovered ? IconImageColor : IconImageGray} 
                    alt="Imagen" 
                    height={100} 
                    width={100}
                /> 
                <span className="m-0" style={{color: hovered ? "white" : "#888"}}>
                    {nombre}
                </span>
                { 
                    selectedCountry?.map((item)=>{
                        return (
                        <span style={{color: hovered ? "white" : "#888",marginTop:0}}>
                            {item}
                        </span>)
                    })
                    
                }
            </div>
            </>
        );
    }; 
    const BotonEstados = ({nombre,index}) => {
        const [hovered, setHovered] = useState(currentZona == index);
        const EstadoSelected = selectedCountry?.estados?.filter((a)=> a?.nombre == nombre )?.[0] 
        const handlerMouseEnter = () =>{
            setHovered(true)
        }
        const handlerMouseLeave = () =>{
            if(EstadoSelected != selectedProvince){
                setHovered(false)
            }
        }
        const handlerMouseClick= () =>{
            if(EstadoSelected != selectedProvince){
                setSelectedProvince(EstadoSelected)
                setCurrentStep(3)  
            }
        }
        return (<>
            <div 
                className="zonas-item" 
                onClick={handlerMouseClick} 
                onMouseEnter={handlerMouseEnter} 
                onMouseLeave={handlerMouseLeave}
                style={
                    {
                        border: hovered ? "solid 2px purple" : "solid 2px #888",
                        background: hovered ? "purple" : "#00000000"
                    }}
            >
                <span style={{color: hovered ? "white" : "#888"}}>
                    {nombre}
                </span> 
            </div>
            </>
        );
    };
    const BotonCiudades = ({nombre,index}) => {
        const [hovered, setHovered] = useState(currentZona == index);
        const CiudadSelected = selectedProvince?.ciudades?.filter((a)=> a?.nombre == nombre )?.[0] 
        const handlerMouseEnter = () =>{
            setHovered(true)
        }
        const handlerMouseLeave = () =>{
            if(CiudadSelected != selectedCity){
                setHovered(false)
            }
        }
        const handlerMouseClick= () =>{
            if(CiudadSelected != selectedCity){
                setSelectedCity(CiudadSelected)
                setCurrentStep(4)
            }
        }
        return (<>
            <div 
                className="zonas-item" 
                onClick={handlerMouseClick} 
                onMouseEnter={handlerMouseEnter} 
                onMouseLeave={handlerMouseLeave}
                style={
                    {
                        border: hovered ? "solid 2px purple" : "solid 2px #888",
                        background: hovered ? "purple" : "#00000000"
                    }}
            >
                <span style={{color: hovered ? "white" : "#888"}}>
                    {nombre}
                </span> 
            </div>
            </>
        );
    };
    const SectionPaises = () =>{
        //const activeBtn = currentPais != 0 && currentState != {}
        return (<>        
            <div className="flex flex-col justify-start items-center text-white p-[10px] lg:p-[50px]">
                <h3 className="text-center mt-[20px] lg:mt-0" >
                    Porfavor, selecciona un pais desde donde quieres comprar BingoVe
                </h3>
 
                 
                <div className="flex flex-wrap w-full items-start justify-center">   
                    {Paises?.map((pais,index)=>{
                        const  icono = pais?.icono
                        const  nombre = pais?.nombre
                        const  activo  = pais?.activo
                        if(!activo)return 
                        if(currentCountry == {}) return null
                        return (<ImagenPaises nombre={nombre} icon={icono} key={`pais-${index}`}/>)
                    })}                 
                </div> 
                 
                
            </div>
        </>)
    }
    const SectionPromotores = () =>{
         
        return (<> 
            <div className="promotores-section ">
                <h3 className="text-center">Porfavor, selecciona un promotor certificado de BingoVe</h3>
                <div className="flex items-center justify-center  min-h-[300px] flex-wrap ">
                    {
                        filteredPromotores?.map((promotor,index)=>{ 
                           return <ImagenPromotores 
                                nombre={promotor?.nombre} 
                                genero={promotor?.genero}
                                index={index} 
                                key={'ImagenPromotores-'+index}
                            />
                        })
                    } 
                </div>
                <button 
                    className={`w-[200px] bg-[purple] p-[10px] pl-[30px] pr-[30px] rounded font-[600]`}
                    onClick={()=>{ setSelectedCity(null);setCurrentStep(3) }}
                    > 
                    VOLVER
                </button>
            </div>
        </>)
    }
    const SectionDetallesContacto = () =>{
         
        return (<> 
            <div className="promotores-section ">
                <h3 className="text-center">Estos son los datos de contacto del promotor</h3>
                <div className="flex items-center justify-center  min-h-[300px] ">
                    <ImagenPromotoresContacto  promotor={selectedPromotor}  /> 
                </div>
                    {
                        isMobile && 
                        <a className="w-full h-[50px] no-underline text-white bg-[green] flex justify-center items-center p-[10px] font-bold rounded"
                        href={ `https://wa.me/${selectedPromotor?.telefono?.replaceAll('+','')}?text=Hola+${selectedPromotor?.nombre}%2C+Quisiera+saber+mas+de+BingoVe.` } target="_blank">
                            Escribir a WhatsApp
                        </a>    
                    }
                <button 
                    className={`w-[200px] bg-[purple] p-[10px] pl-[30px] pr-[30px] rounded font-[600] mt-3`}
                    onClick={()=>{ setSelectedPromotor(null);setCurrentStep(4) }}
                    > 
                    VOLVER
                </button>
            </div>
        </>)
    }
    const SectionEstados = () =>{
        const Estados = selectedCountry?.estados
        return (<>
        
            <div className="zonas-section">
                <h3 className="text-center">Porfavor, selecciona la provincia desde donde quieres comprar BingoVe</h3>
                <div className="zonas-content">
                    {Estados?.map((item,index)=>{
                        return (<BotonEstados nombre={item?.nombre}  index={index} key={'BotonEstados-'+index}/>)
                    })} 
                    
                </div>
                    <button 
                        className={`w-[200px] bg-[purple] p-[10px] pl-[30px] pr-[30px] rounded font-[600]`}
                        onClick={()=>{ setSelectedCountry(null);setCurrentStep(1)  }}
                        > 
                        VOLVER
                    </button>
            </div>
        </>)
    }
    const SectionCiudades = () =>{
        const Ciudades = selectedProvince?.ciudades
        return (<>        
            <div className="zonas-section">
                <h3 className="text-center">Porfavor, selecciona la ciudad desde donde quieres comprar BingoVe</h3>
                <div className="zonas-content">
                {Ciudades?.map((item,index)=>{
                        for(let x = 0 ; x < promotores.length ; x++){

                            const promotorActual = promotores[x].ciudad.toLowerCase()
                            const ciudadActual = item.nombre.toLowerCase()
                            if(promotorActual.includes(ciudadActual)){ 
                                return (<BotonCiudades nombre={item?.nombre}  index={index} key={'BotonCiudades-'+index}/>)
                            }
                        }   
                    })}  
                </div>
                <button 
                    className={`w-[200px] bg-[purple] p-[10px] pl-[30px] pr-[30px] rounded font-[600]`}
                    onClick={()=>{ setSelectedProvince(null);setCurrentStep(2)  }}
                    > 
                    VOLVER
                </button>
            </div>
        </>)
    }

    const DinamicContentSteps = () => {
        if(!selectedCountry){
           // setCurrentStep(1) 
        } else {
            if(!selectedProvince){
              //  setCurrentStep(2)
                return <SectionEstados />
            } else {
                if( !selectedCity){
                  //  setCurrentStep(3)
                    return <SectionCiudades />
                } else { 
                    if( !selectedPromotor){
                     //   setCurrentStep(4)
                        return <SectionPromotores />
                    }  else {
                      //  setCurrentStep(5)
                        return <SectionDetallesContacto />
                    }  
                }
                
            }
        }  
        return <SectionPaises />          

    }


    const SearchFromPais = async ()=>{
        const params = new URLSearchParams(location.search);
        const paisParam =  params.get('pais');  
        if (paisParam) {
            const PaisSelected = Paises.filter((a)=> a?.nombre.toLowerCase() == paisParam.toLowerCase() )?.[0]
            if(!selectedCountry){ 
                setCurrentStep(2) 
                setSelectedCountry(PaisSelected)
                setBanner(false);
            }    
        }
    }
    const SearchFromProvincia = async ()=>{
        const params = new URLSearchParams(location.search); 
        const provinciaParam = params.get('estado');  
        if (provinciaParam && selectedCountry) { 
            const EstadoSelected = selectedCountry?.estados?.filter((a)=> a?.nombre.toLowerCase() == provinciaParam.toLowerCase() )?.[0]  
            if(!selectedProvince){
                setCurrentStep(3) 
                console.log(EstadoSelected)
                console.log(selectedProvince)
                console.log(provinciaParam)
                setSelectedProvince(EstadoSelected) 
                setBanner(false);
            }
        } 
    }
    const SearchFromCiudad = async ()=>{
        const params = new URLSearchParams(location.search); 
        const ciudadParam = params.get('ciudad'); 
        if (ciudadParam && selectedProvince) {
            const CiudadSelected = selectedProvince?.ciudades?.filter((a)=> a?.nombre.toLowerCase() == ciudadParam.toLowerCase() )?.[0]  
            if(!selectedProvince){
                setCurrentStep(3)  
                setSelectedCity(CiudadSelected) 
            }
        } 
    }
    useEffect(() => {        
        SearchFromPais()
    }, [location.search,Paises]);

    useEffect(() => {        
        SearchFromProvincia()
     }, [selectedCountry]);

     useEffect(() => {        
        SearchFromCiudad()
     }, [selectedProvince]);






    return (
        <div className="pages-promotor">
            <Header noLogin={true} />
              {
                banner && 
                <div className="promotor-section-1 promotor-section">
                    <CarrouselJs />
                </div>
              }
            <div className="promotor-section-0">
                <span></span>
                <span></span>
            </div>
            <div className="promotor-section-0" style={{background:"transparent",marginTop:10}}>
                <span className="text-center">Sigue los pasos para contactar a un promotor certificado de BingoVe</span> 
            </div>
            <div className="promotor-section" id="PROMOTORES">
                
                {
                    !isMobile && 
                    <div className="promotor-steps-all">
                        <StepsTemplate texto={'Elige el Pais'}  index={1} />
                        <StepsTemplate texto={'Elige la Provincia'}  index={2} />
                        <StepsTemplate texto={'Elige la Ciudad'}  index={3} />
                        <StepsTemplate texto={'Elige un Promotor'} index={4} />
                        <StepsTemplate texto={'Datos de Contacto'} index={5} />

                        {/*<StepsTemplate texto={'Verifica tu carton'} index={0} noIcon />*/}
                    </div>
                }

                {
                    isMobile &&
                    <div className="promotor-steps-all">                        
                        {/*currentStep == 0 && <StepsTemplate texto={'Verifica tu carton'} index={0} noIcon />*/}
                        {currentStep == 1 && <StepsTemplate texto={'Elige el Pais'}  index={1} />}
                        {currentStep == 2 &&  <StepsTemplate texto={'Elige la Provincia'}  index={2} />}
                        {currentStep == 3 &&  <StepsTemplate texto={'Elige la Ciudad'}  index={3} />}
                        {currentStep == 4 && <StepsTemplate texto={'Elige un Promotor'} index={4} />}
                        {currentStep == 5 && <StepsTemplate texto={'Datos de Contacto'} index={5} />}                        
                    </div>
                }
                <div className="promotor-steps-content ">
                    <DinamicContentSteps />
                </div>
                    
                
            </div>
 
            <Footer noLogin={true} />
        </div>
      
    );
  }
  export default Promotores;
 
