import React, { useCallback, useEffect, useRef, useState, version } from "react";
import api from "../../services/api";
import {Header,Footer,Youtube,Carton,Tablero,Chat,Loader} from '../../components';
import './Cartones.css';
import { isMobile } from "react-device-detect";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Button } from "react-bootstrap";
import {Link } from 'react-router-dom';

function Redireccion() {
    const location = useLocation();
    const [Cartones,setCartones] = useState([]);
    const [serialToAdd,setSerialToAdd] = useState();      
    const [premioDiagonal,setPremioDiagonal] = useState(0);
    const [premioEsquinas,setPremioEsquinas] = useState(0);
    const [premioLleno,setPremioLleno] = useState(0);
    const [AppVersion,setAppVersion] = useState(0); 
    const [DiaSorteo,setDiaSorteo] = useState(0);
    const [Promotores,setPromotores] = useState(0); 
    const [CartonesLocal,setCartonesLocal] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [Orden,setOrden] = useState("asc")
    const [listMode,setListMode] = useState(false);
    const Consola = (value) =>  console.log(value)

    //todo para  configuraciones
    const [configs, setConfigs] = useState([]);
    const [link, setLink] = useState();
    const [vendidos,setVendidos] = useState();
    const [TotalRepartir, setTotalRepartir] = useState(0)
    const [sorteoActual, setSorteoActual] = useState();
    const [historial, setHistorial] = useState(false);
    const [PremiosLittle, setPremiosLittle] = useState(0)
    const [PremiosBig, setPremiosBig] = useState(0)
    const [videoHome1, setVideoHome1] = useState({})
    const [videoHome2, setVideoHome2] = useState({})
    const [videoUltimo, setVideoUltimo] = useState({})
    const [publicidad, setPublicidad] = useState(0)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState();
    const [hora, setHora] = useState();
    const [rondas, setRondas] = useState(1);
    const [precioCarton, setPrecioCarton] = useState(1);
    const [ventasStatus, setVentasStatus] = useState(false);
    const [promotorStatus, setPromotorStatus] = useState(false);
    const [promotorEmpleo, setPromotorEmpleo] = useState(false); 
    const [adminStatus, setAdminStatus] = useState(false);
    //####################### 
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);

     
     
    const goToCartones = ()=>{

        
    }
    const goToEspecial = ()=>{
 
    }

    return (
        <div className="pages-cartones flex-1"  > 
            <Header noLogin={true} /> 
            <div className="page-content flex-1 flex h-full mt-20 mb-20"  >
                <div className="flex flex-wrap  row-principal cartones-row flex-1 h-full gap-10 items-center justify-center">
                    <h2 style={{color:"white"}}>
                        Cual sorteo quieres comprar?
                    </h2>
                    <Link to="/comun">
                        <Button style={{background:"purple",color:"white"}}> 
                            <span>SORTEO COMUN #S0050 </span>
                            <br />
                            <span>Domingo 07-04-2024  6:00pm</span>
                            <br />
                            <span>PRECIO 1$ / Carton</span>
                        </Button> 
                    </Link>
                    <Link to="/especial">
                        <Button className="flex  flex-col" style={{background:"gold",color:"black",fontWeight:"bold"}}> 
                            <span>ESPECIAL DE LAS MADRES #E0055</span>
                            <br />
                            <span>Domingo 14-05-2024 6:00pm</span>
                            <br />
                            <span>PRECIO 3$ / Carton</span>
                        </Button>
                    </Link>
                </div>
            </div>
            <Footer noLogin={true} />
        </div>
      
    );
  }
  export default Redireccion;
