import React, { useEffect, useState } from "react";
import api from "../../services/api";
import logo from '../../logo.svg';
import {SiteLogo} from '../../components'
import '../../App.css';
import './footer.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
function Footer() {
    const [user, setUser] = useState();
    return (
        <>
            <div id="footer">
                <div className="flex flex-col  items-center justify-start lg:flex-row lg:justify-between w-full p-[20px]">
                    <div className="w-full lg:w-1/3 flex flex-col items-center justify-start">
                        <div className="footer-inner-row">
                            <SiteLogo />
                        </div>
                        <div className="footer-inner-row">
                            <span>Prueba tu #SUERTE</span>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 flex flex-col items-center justify-center">
                        <div className="footer-inner-row">
                            <span>LEGAL</span>
                        </div>
                        <div className="footer-inner-row">
                            <span>Teminos y condiciones</span>
                            <span>Politica de cookies</span>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 flex flex-col items-center justify-center">
                        <div className="footer-inner-row">
                            <span>SOCIAL MEDIA</span>
                        </div>
                        <div className="flex gap-[30px]">
                            <a  href="https://www.instagram.com/bingo_ve1.0/" 
                                className="gap-[10px] text-white flex items-center justify-center" 
                                target="_BLANK"
                                > 
                                <img src={"https://bingove.com/images/instagram_white.png"} height={30}  width={30} />
                            </a>
                            <a  href="https://www.facebook.com/bingove1.0" 
                                className="gap-[10px] text-white flex items-center justify-center" 
                                target="_BLANK"
                                > 
                                <img src={"https://bingove.com/images/facebook_white.png"} height={30} width={30} /> 
                             </a>
                            <a  href="https://www.youtube.com/@bingo_ve" 
                                className="gap-[10px] text-white flex items-center justify-center"
                                target="_BLANK" 
                                > 
                                <img src={"https://bingove.com/images/youtube_white.png"} height={30}  width={30} /> 
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-row row2">
                    <span>©2022 - 2024 NINJA STUDIO All Rights Reserved.</span>
                </div>
            </div>
        </>
      
    );
  }
  
  export default Footer;